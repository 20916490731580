<template>
  <toast/>
  <div>
    <router-view/>
  </div>
  <div>
    <Footer/>
  </div>
  <Dialog :visible="true" :closable="false" :modal="true" :draggable="false">
        <div style="width: 50vw;" class="py-5">
          <h2 class="pb-3 text-center">Under Maintenance</h2>
          <p class="text-center">The tool is under maintenance, it will be updated soon.</p>
          <p class="text-center">We apologies for any inconvenience that may be caused.</p>
        </div>
  </Dialog>
</template>

<script>

import Footer from "@/Footer.vue";

export default {
  components: {
    'Footer': Footer,
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss">

</style>
